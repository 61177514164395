import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import usersApi from '@/api/common/users';
import { debounce } from 'vue-debounce';
import { mapState } from "vuex";
export default {
    extends: baseComponent,
    props: {
        detailUserItem: {
            type: Object,
            default: null,
        }
    },
    watch: {
        detailUserItem: {
            deep: true,
            handler() {
                console.log('The list of colours has changed!');
                this.getSearchQueryResult(1);
            }
        }
    },
    data() {
        return {
            debounceFnc: null,
            elements: {
                querySearch: "",
                data: [],
                pagingItem: {
                    pageIndex: 1,
                    pageSize: 20,
                    numberOfPage: 1,
                    outRowsNumber: 0,
                    orderBy: "created_at",
                    directionSort: "desc"
                }
            },
            elementDetail: null,
            postNewCommentModel: "",
            postModifiedCommentModel: {},
            module: "user",
        };
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
        }),
        defaultPagingItem() {
            return {
                pageIndex: 1,
                pageSize: 20,
                numberOfPage: 1,
                outRowsNumber: 0,
                orderBy: "created_at",
                directionSort: "desc"
            };
        },
        requestParam() {
            return {
                querySearch: this.elements.querySearch,
                pageSize: this.elements.pagingItem.pageSize,
            };
        },
        contentInValid() {
            let format = /[\\`'"]/;
            //console.log(format.test(this.detailUserNote.noteContentEdit));
            //return format.test(this.detailUserNote.noteContentEdit);
            return false; //format.test(this.detailUserNote.noteContentEdit);
        }
    },
    created() {
        this.getSearchQueryResult(1);
        this.debounceFnc = debounce(() => {
            this.getSearchQueryResult(1);
        }, 1000);
    },
    methods: {
        getNoteList(pageNumber) {
            this.showLoading();
            usersApi.getNoteElementsList(this.module, this.detailUserItem.id, pageNumber, this.requestParam).then(response => {
                if (response.data.result === 0) {
                    // response.data.data.data.forEach(item => {
                    //     item.isEditMode = false;
                    // });
                    this.$set(this.elements, "data", response.data.data.data);
                    this.$set(this.elements, "pagingItem", response.data.data.pagingItem);
                    this.hideLoading();
                } else {
                    this.$set(this.elements, "data", []);
                    commonAct.showError(response.data.message);
                    this.hideLoading();
                }
            }).catch(error => {
                this.$set(this.elements, "data", []);
                console.error(error);
                commonAct.showError(error);
                this.hideLoading();
            });
        },
        getSearchQueryResult(pageNumber) {
            this.getNoteList(pageNumber);
        },
        pageClickHandle(pageNumber) {
            this.getSearchQueryResult(pageNumber);
        },
        refreshClick() {
            this.elements.querySearch = "";
            this.getSearchQueryResult(1);
        },
        onSearchInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFnc) this.debounceFnc();
            } else {
                this.getSearchQueryResult(1);
            }
        },
        postNewComment() {
            this.showLoading();
            usersApi.saveDetailUserNote({
                noteId: null,
                userId: this.detailUserItem.id,
                itemId: this.detailUserItem.id,
                module: this.module,
                noteContentEdit: this.postNewCommentModel
            }).then(response => {
                if (response.data && response.data.result === 0) {
                    let userNoteCreated = response.data.data;
                    if (this.elements.pagingItem.pageIndex === 1) {
                        this.elements.data.unshift(userNoteCreated);
                        if (this.elements.data.length > this.elements.pagingItem.pageSize) this.elements.data.splice(-1, 1);
                    }
                    this.elements.pagingItem.outRowsNumber += 1;
                    this.$emit("newestCommentUpdated", this.postNewCommentModel);
                    this.$set(this.detailUserItem, "noteContent", this.postNewCommentModel);
                    this.showSuccessToast("User note information has been saved");
                    this.postNewCommentModel = "";
                } else {
                    commonAct.showError(response.data.message);
                }
                this.hideLoading();
            }).catch(error => {
                console.error(error);
                commonAct.showError(error);
                this.hideLoading();
            });
        },
        editComment(commentItem) {
            console.log("commentItem", commentItem);
            this.postModifiedCommentModel = commentItem;
            this.$set(this.postModifiedCommentModel, "noteContentEdit", commentItem.noteContent);
            this.$set(commentItem, "isEditMode", true);
        },
        postModifiedComment() {
            this.showLoading();
            usersApi.saveDetailUserNote({
                noteId: this.postModifiedCommentModel.noteId,
                userId: this.detailUserItem.id,
                itemId: this.detailUserItem.id,
                module: this.module,
                noteContentEdit: this.postModifiedCommentModel.noteContentEdit
            }).then(response => {
                if (response.data && response.data.result === 0) {
                    let userNoteCreated = response.data.data;
                    let foundedItemIndex = this.elements.data.findIndex(x => { return x.noteId === userNoteCreated.noteId });
                    if (foundedItemIndex > -1) {
                        this.elements.data[foundedItemIndex].isEditMode = false;
                        this.$set(this.elements.data, foundedItemIndex, userNoteCreated);
                        if (foundedItemIndex === 0) {
                            this.$emit("newestCommentUpdated", this.postModifiedCommentModel.noteContentEdit);
                            this.$set(this.detailUserItem, "noteContent", this.postModifiedCommentModel.noteContentEdit);
                        }
                    }
                    this.showSuccessToast("User note information has been saved");
                    this.postModifiedCommentModel = {};
                } else {
                    commonAct.showError(response.data.message);
                }
                this.hideLoading();
            }).catch(error => {
                console.error(error);
                commonAct.showError(error);
                this.hideLoading();
            });
        },
        cancelEdit() {
            let foundedItemIndex = this.elements.data.findIndex(x => { return x.noteId === this.postModifiedCommentModel.noteId });
            if (foundedItemIndex > -1) {
                this.elements.data[foundedItemIndex].isEditMode = false;
                this.postModifiedCommentModel.noteContentEdit = "";
            }
            this.postModifiedCommentModel = {};
        },
        deleteNote(itemDelete) {
            commonAct.showConfirm("Delete comment. This cannot be undone?", () => {
                this.showLoading();
                usersApi.deleteNoteById(itemDelete.noteId).then(response => {
                    if (response.data.result === 0) {
                        let foundedItemIndex = this.elements.data.findIndex(x => { return x.noteId === itemDelete.noteId });
                        if (foundedItemIndex > -1) {
                            this.elements.data.splice(foundedItemIndex, 1);
                            this.getSearchQueryResult(this.elements.pagingItem.pageIndex);
                            if (this.elements.data.length > 0) this.$set(this.detailUserItem, "noteContent", this.elements.data[0].noteContent);
                            else this.$set(this.detailUserItem, "noteContent", "");
                        }
                    } else {
                        commonAct.showError(response.data.message);
                    }
                    this.hideLoading();
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);
                    this.hideLoading();
                });
            });
        },
    }
}